import React, { useState } from "react";
import "./waitlist.css"; // Make sure to import the CSS file for styling

import illustration from "../Images/painter.png"; // Replace with the path to your illustration image
import { ref, push, set } from "firebase/database";

import { database } from "../firebase-config.js";

function WaitlistSection() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [message, setMessage] = useState(""); // State to hold the message

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const newUserRef = ref(database, "users");
      const newUser = push(newUserRef);
      await set(newUser, {
        name: name,
        email: email,
        city: city,
        country: country,
      });
      setMessage("Thank you for registering!"); // Success message
      setName("");
      setEmail("");
      setCity("");
      setCountry("");
    } catch (error) {
      setMessage(`Error adding user: ${error.message}`); // Display error message
    }
  };

  return (
    <section className="waitlist-section">
      <div className="waitlist-content">
        <h2>
          Join <span style={{ color: "white" }}>the Waitlist</span>
        </h2>

        <form className="waitlist-form" onSubmit={handleSubmit}>
          <input
            className="name"
            type="text"
            placeholder="Full name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="location-fields">
            <input
              className="city"
              type="text"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <input
              className="country"
              type="text"
              placeholder="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </div>
          <input
            className="email"
            type="email"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <div className="form-submit-message">
            <button className="cta-button" type="submit">
              Get Early Access
            </button>
            {message && <p className="form-message">{message}</p>}
          </div>
        </form>
      </div>
      <div className="waitlist-content-mobile">
        <h2>
          Join <span style={{ color: "white" }}>the Waitlist</span>
        </h2>

        <form className="waitlist-form-mobile" onSubmit={handleSubmit}>
          <input
            className="name-mobile"
            type="text"
            placeholder="Full name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <input
            className="city-mobile"
            type="text"
            placeholder="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <input
            className="country-mobile"
            type="text"
            placeholder="Country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />

          <input
            className="email-mobile"
            type="email"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <div className="form-submit-message-mobile">
            <button className="cta-button" type="submit">
              Get Early Access
            </button>
            {message && <p className="form-message-mobile">{message}</p>}
          </div>
        </form>
      </div>
    </section>
  );
}

export default WaitlistSection;
