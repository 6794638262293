import React from "react";
import "./features.css";
import Line from "../Images/line.png";
import Line2 from "../Images/line-two.png";
import Line3 from "../Images/line-three.png";
import Line3Mobile from "../Images/line-3-mobile.png";
import Line2Mobile from "../Images/line-2-mobile.png";
import Line1Mobile from "../Images/line-1-mobile.png";
import Step from "../Images/step.png";
import StepMobile from "../Images/step-mobile.png";
import Fixer from "../Images/fixer.png";
import FixerMobile from "../Images/fixer-mobile.png";
import Painter from "../Images/painter.png";
import PainterMobile from "../Images/painter-mobile.png";
import Ladder from "../Images/ladder.png";
import LadderMobile from "../Images/ladder-mobile.png";

function Features({ scrollToSignUp }) {
  // Accept scrollToSignUp as a prop
  return (
    <section className="features-section">
      <h1 className="text-one-mobile">Ready, Steady...</h1>
      <h1 className="text-two-mobile">TYDI!</h1>

      <div className="section-one-mobile">
        <div className="welcome-text">
          <h1 className="text-one">Ready, Steady...</h1>
          <h1 className="text-two">TYDI!</h1>
          <h2 className="text-three">
            Taking care of your home should be this easy.
          </h2>
          <button className="cta-button" onClick={scrollToSignUp}>
            Get Early Access
          </button>
        </div>

        <div>
          <img
            src={PainterMobile}
            alt="Illustration"
            className="painter-mobile-img"
          />
          <img src={Line1Mobile} alt="Line!Mobile" className="line-1-mobile" />
        </div>
      </div>

      <div className="section-one">
        <div className="welcome-text">
          <h1 className="text-one">Ready, Steady...</h1>
          <h1 className="text-two">TYDI!</h1>
          <h2 className="text-three">
            Taking care of your home should be this easy.
          </h2>
          <button className="cta-button" onClick={scrollToSignUp}>
            Get Early Access
          </button>
        </div>

        <div>
          <img src={Painter} alt="Illustration" className="painter-img" />
          <img
            src={PainterMobile}
            alt="Illustration"
            className="painter-mobile-img"
          />
        </div>
      </div>

      <div className="section-two-mobile">
        <div className="ladder-section">
          <div className="ladder-mobile-img">
            <img src={LadderMobile} alt="LadderMobile" />
          </div>

          <div className="section-two-mobile-text">
            <h1 className="text-four">Create your own</h1>
            <h1 className="text-five">home profile</h1>
            <button className="cta-button2" onClick={scrollToSignUp}>
              Get Early Access
            </button>
          </div>
        </div>
        <img src={Line2Mobile} alt="Line2Mobile" className="line-2-mobile" />
        <div className="third-mobile">
          <div className="section-two-text3-mobile">
            <h1 className="text-four">Instantly match</h1>
            <h1 className="text-five">to trade professionals</h1>
            <button className="cta-button2" onClick={scrollToSignUp}>
              Get Early Access
            </button>
          </div>
          <img src={StepMobile} alt="Step" className="step-img-mobile" />
        </div>

        <div className="fourth-mobile">
          <img src={Line3Mobile} className="line-3-mobile" alt="Line3Mobile" />
          <img className="fixer-img-mobile" src={FixerMobile} alt="Step" />
          <div className="section-two-text4-mobile">
            <h1 className="text-four">Relax and get on</h1>
            <h1 className="text-five">with your day</h1>
            <button className="cta-button2" onClick={scrollToSignUp}>
              Get Early Access
            </button>
          </div>
        </div>
      </div>

      <div className="section-two">
        <div className="line-img">
          <img src={Line} alt="Line" />
        </div>
        <div className="ladder-section">
          <div className="ladder-mobile-img">
            <img src={LadderMobile} alt="LadderMobile" />
          </div>

          <div className="ladder-img">
            <img src={Ladder} alt="Ladder" />
          </div>
          <div className="section-two-mobile-text">
            <h1 className="text-four">Create your own</h1>
            <h1 className="text-five">home profile</h1>
            <button className="cta-button2" onClick={scrollToSignUp}>
              Get Early Access
            </button>
          </div>
          <div className="section-two-text">
            <h1 className="text-four">Create your own</h1>
            <h1 className="text-five">home profile</h1>
            <button className="cta-button2" onClick={scrollToSignUp}>
              Get Early Access
            </button>
          </div>
        </div>

        <div>
          <div className="line-two-img">
            <img src={Line2} alt="Line Two" />
          </div>
          <div className="section-two-text3-mobile">
            <h1 className="text-four">Instantly match</h1>
            <h1 className="text-five">to trade professionals</h1>
            <button className="cta-button2" onClick={scrollToSignUp}>
              Get Early Access
            </button>
          </div>
          <div className="third">
            <div className="section-two-text3">
              <h1 className="text-four">Instantly match</h1>
              <h1 className="text-five">to trade professionals</h1>
              <button className="cta-button2" onClick={scrollToSignUp}>
                Get Early Access
              </button>
            </div>

            <img src={Step} alt="Step" className="step-img" />
          </div>
        </div>

        <div>
          <div className="line-two-img">
            <img
              src={Line3}
              alt="Line Three"
              style={{
                width: "800px",
                marginRight: "400px",
                position: "relative",
                top: "0.1em",
                height: "200px",
              }}
            />
          </div>
          <div className="fourth">
            <img className="fixer-img" src={Fixer} alt="Step" />
            <div className="section-two-text4">
              <h1 style={{ fontSize: "42px" }} className="text-four">
                Relax and get on
              </h1>
              <h1 style={{ fontSize: "42px" }} className="text-five">
                with your day
              </h1>
              <button className="cta-button2" onClick={scrollToSignUp}>
                Get Early Access
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
