import React, { useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import Header from "./components/Header";
import Features from "./components/Features";
import SignUpForm from "./components/SignUpForm";
import Footer from "./components/Footer";
import "./App.css";

function App() {
  const signUpRef = useRef(null); // Create a ref for the SignUpForm
  const featuresRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToSignUp = () => {
    signUpRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToFeatures = () => {
    featuresRef.current?.scrollIntoView({ behavior: "smooth" }); // Function to scroll to Features
  };
  const scrollToContact = () => {
    contactRef.current?.scrollIntoView({ behavior: "smooth" }); // Function to scroll to Features
  };
  return (
    <BrowserRouter>
      <div>
        <Header
          scrollToSignUp={scrollToSignUp}
          scrollToFeatures={scrollToFeatures}
          scrollToContact={scrollToContact}
        />
        <div ref={featuresRef}>
          <Features scrollToSignUp={scrollToSignUp} />{" "}
        </div>
        <div ref={signUpRef}>
          <SignUpForm />
        </div>
        <div ref={contactRef}>
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
