import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'; // for Firestore
import { getDatabase } from 'firebase/database'; // for Realtime Database

const firebaseConfig = {
    apiKey: "AIzaSyC9OOKBmn5S6DTNRZzVxtaOtLovb4fWwCU",
    authDomain: "website-c843b.firebaseapp.com",
    databaseURL: "https://website-c843b-default-rtdb.europe-west1.firebasedatabase.app", // Updated database URL
    projectId: "website-c843b",
    storageBucket: "website-c843b.appspot.com",
    messagingSenderId: "1084451031368",
    appId: "1:1084451031368:web:514c6f4df2f79173aacb7d",
    measurementId: "G-ZE56MEYK9L"

};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app); // for Firestore
export const database = getDatabase(app); // for Realtime Database