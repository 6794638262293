import React from "react";
import "./header.css";
import logo from "../Images/Logo.png";

function Header({ scrollToSignUp, scrollToFeatures, scrollToContact }) {
  // Accept scrollToSignUp as a prop

  return (
    <header className="header">
      <div className="header-logo">
        <a href="http://www.tydiup.com">
          <img src={logo} alt="TYDIUP Logo" />
        </a>
      </div>
      <div className="header-group">
        <div className="header-links">
          <button className="mobile-button1" onClick={scrollToFeatures}>
            Features
          </button>
          <button className="mobile-button2" onClick={scrollToContact}>
            Contact
          </button>
        </div>
        <div className="header-cta">
          <button className="cta-button" onClick={scrollToSignUp}>
            Get Early Access
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
