import React from "react";
import "./footer.css";
import iconFacebook from "../Images/facebook.png";
import iconInstagram from "../Images/Instagram.png";
import iconTwitter from "../Images/twitter.png";
import iconTiktok from "../Images/tiktok.png";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="contacts">
          <p> hello@tydiup.com</p>
        </div>
        <div className="footer-social">
          <a href="https://facebook.com">
            <img
              style={{ height: "50px", width: "50px" }}
              src={iconFacebook}
              alt="Facebook"
            />
          </a>
          <a href="https://instagram.com">
            <img
              style={{ height: "50px", width: "50px" }}
              src={iconInstagram}
              alt="Instagram"
            />
          </a>

          <a href="https://tiktok.com">
            <img
              style={{ height: "50px", width: "50px" }}
              src={iconTiktok}
              alt="Instagram"
            />
          </a>
          <a href="https://twitter.com/">
            <img
              style={{ height: "50px", width: "50px" }}
              src={iconTwitter}
              alt="Twitter"
            />
          </a>
        </div>
      </div>
      <div className="footer-content-mobile">
        <div className="contacts-mobile">
          <p> hello@tydiup.com</p>
        </div>
        <div className="footer-social-mobile">
          <a href="https://facebook.com">
            <img
              style={{ height: "40px", width: "40px", marginRight: "10px" }}
              src={iconFacebook}
              alt="Facebook"
            />
          </a>
          <a href="https://instagram.com">
            <img
              style={{ height: "40px", width: "40px", marginRight: "10px" }}
              src={iconInstagram}
              alt="Instagram"
            />
          </a>

          <a href="https://tiktok.com">
            <img
              style={{ height: "40px", width: "40px", marginRight: "10px" }}
              src={iconTiktok}
              alt="Instagram"
            />
          </a>
          <a href="https://twitter.com/">
            <img
              style={{ height: "40px", width: "40px" }}
              src={iconTwitter}
              alt="Twitter"
            />
          </a>
        </div>
      </div>
      <div className="footer-bar">
        <p>© 2024 TYDIUP Technologies</p>
      </div>
    </footer>
  );
}

export default Footer;
